$dark: #1d1e25;
$grey: rgb(231, 231, 231);
$highlight: #fc5c7d;

* {
  color: #1d1e25;
  font-family: "Urbanist", sans-serif;
}

.app__section {
  padding: 5rem 0;
}

.app {
  &__navbar {
    height: 100px;
    position: relative;
    display: flex;
    justify-content: center;
    width: 80%;
    border-bottom: 1px solid $grey;
    margin: 0 auto;
    &__circle {
      position: absolute;
      padding: 1rem;
      margin: 0 1rem;
      height: 85px;
      top: 65px;
      background: #fff;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        transform: scale(1.1);
      }
    }
  }

  // Hero

  &__hero {
    padding: 5rem 0;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("./assets/hero_background.png");
    h1 {
      font-size: 4rem;
      font-weight: 700;
    }
  }

  &__about {
    height: 550px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #fc5c7d;
    // background-image: url("./assets/About Us.png");

    p {
      font-size: 1.3rem;
    }
  }

  &__testionial {
    .testimonial-img {
      border-radius: 50%;
      width: 120px;
      height: 120px;
      margin: auto;
    }

    &__card {
      border: 2px solid #ebefff;
    }
  }

  &__features {
    h1 {
      font-size: 4rem;
    }
    &__header {
      display: flex;
      justify-content: center;
      margin: 1rem 0;
    }
    .feature__card {
      p {
        font-size: 1rem;
      }
    }
  }

  &__benefits {
    padding: 6rem;

    .benefit__action__btn {
      background: $highlight;
      border-color: $highlight;
      padding: 0.5rem 3rem;
    }
    h1 {
      font-weight: 700;
      font-size: 3rem;
    }

    .benefit__card {
      h5 {
        font-weight: 700;
      }
      p {
        width: 90%;
      }
    }
  }

  // download
  &__download {
    background-color: $highlight;
    padding: 4rem;
    border-radius: 0.5rem;
    color: #fff;
    position: relative;
    overflow: hidden;

    h1 {
      color: #fff;
      font-weight: 500;
    }
    p {
      color: #fff;
      margin: 2rem 0;
      width: 50%;
    }

    &__actions {
      .img {
        height: fit-content;
        &:nth-child(1) {
          margin-right: 2rem;
        }
      }
    }

    &__img {
      position: absolute;
      right: 0;
      top: -30%;
      animation: appDownload 10s infinite;
    }
  }

  .headline {
    &__line {
      width: 60px;
      height: 2px;
      background: $dark;
      margin-right: 1rem;
      &.light {
        background: #fff;
      }
    }
    &__text {
      font-weight: 700;
    }
  }

  @keyframes appDownload {
    0% {
      top: 0%;
    }
    50% {
      top: -20%;
    }
    100% {
      top: 0%;
    }
  }

  // footer
  &__footer {
    padding: 5rem 0;
    &__list {
      padding: 3rem 0;
      list-style: none;
      display: flex;
      justify-content: center;
      font-weight: 800;
      > li {
        margin: 0 2rem;
      }
    }

    &__text {
      font-weight: 800;
      text-align: center;
    }
  }
}

.highlight {
  color: $highlight;
}

details {
  padding: 2rem 0;
  &:not(:first-child),
  &:not(:last-child) {
    border-bottom: 1px solid #ebefff;
  }

  &[open] {
    summary {
      > span {
        color: $highlight;
      }

      > .opened {
        display: block;
        color: #fff;
      }
      > .closed {
        display: none;
      }
    }
  }

  summary {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    list-style: none;
    transition: 0.2s ease;
    font-size: 1.3rem;

    &:not(:first-child) {
      background: $highlight;
    }

    .opener {
      background: $highlight;
      padding: 0.5rem 1rem;
      transition: 0.2s ease-in-out;
    }

    > .opened {
      display: none;
    }
    > .closed {
      display: block;
      background: #ebefff;
    }

    &::-webkit-details-marker {
      display: none !important;
    }
  }
}

.counters {
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-color: $highlight;
    text-align: center;
    color: #fff;
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}

.btn-main {
  background: $highlight;
  color: #fff;
}
